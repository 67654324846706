




































































































































import Base from '@/mixins/Base.vue';
import { IRole, IRoleResponse } from '@/interfaces/role';

const component = Base.extend({
    data() {
        return {

            role: undefined as IRole | any,
            default_permissions: [] as Array<any>,
            actions: ['create', 'read', 'update', 'delete'],
        };
    },
    mounted() {
        this.getRole();
    },
    methods: {
        countChecked(slug: string): number {
            let count: number = 0;
            this.actions.forEach((a) => {
                const perm: string = `${slug}.${a}`;
                if (this.role.permissions && this.role.permissions.indexOf(perm) !== -1) count += 1;
            });

            return count;
        },
        isIndeterminate(slug: string): boolean {
            const count = this.countChecked(slug);
            return count > 0 && count < this.actions.length;
        },
        toggleCheckAll(value: any, slug: any): void {
            if (value) this.checkAll(slug);
            else this.unCheckAll(slug);
        },

        checkAll(slug: any): void {
            const default_permission = this.default_permissions.find((p) => p.slug === slug);

            this.actions.forEach((a) => {
                if (default_permission.actions.indexOf(a) !== -1) {
                    const perm = `${slug}.${a}`;
                    if (this.role.permissions && this.role.permissions.indexOf(perm) === -1) this.role.permissions.push(perm);
                }
            });
        },
        unCheckAll(slug: any): void {
            this.actions.forEach((a) => {
                const perm = `${slug}.${a}`;
                if (this.role.permissions) {
                    const index = this.role.permissions.indexOf(perm);
                    this.$delete(this.role.permissions, index);
                }
            });
        },
        getRole(): void {
            this.get<IRoleResponse>(`roles/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.role = data.data;

                    this.default_permissions = [];

                    this.role.default_permissions.forEach((perm: any) => {
                        const arr = perm.split('.');
                        arr.pop();
                        const str = arr.join('.');

                        if (this.default_permissions.findIndex((p) => p.slug === str) === -1) {
                            const actions: Array<string> = [];
                            this.actions.forEach((a) => {
                                if (this.role.default_permissions.findIndex((p: any) => p === `${str}.${a}`) !== -1) actions.push(a);
                            });
                            this.default_permissions.push({ slug: str, actions });
                        }
                    });
                });
        },
        saveRole(): void {
            this.put<IRoleResponse>(`roles/${this.$route.params.id}`, {
                ...this.role,
            }).then(({ data }) => {
                this.role = data.data;
                this.$emit('success', { message: `role ${data.data.name} updated` });
                // if current role, update
                if (this.currentUser.role && this.currentUser.role.id === this.role.id) {
                    this.$store.dispatch('updateRole', this.role);
                }
            });
        },
    },
});

export default component;
